<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        <i class="icon-note"></i> Add Product
        <div class="card-header-actions">
          <a class="card-header-action" href="dinar-products" rel="noreferrer noopener">
            <small class="text-muted">Back</small>
          </a>
        </div>
      </b-card-header>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
            <div class="sk-three-bounce">
                <div class="sk-child sk-bounce1"></div>
                <div class="sk-child sk-bounce2"></div>
                <div class="sk-child sk-bounce3"></div>
            </div>
        </div>
        <b-row>
          <b-col sm="12">
            <!--<h6>Simple Form</h6>-->
            <b-form v-on:submit.prevent="dinarPost"  novalidate>
                <b-form-group id="skus"
                              label="Product ID"
                              label-for="password">
                  <b-form-input id="sku"
                                type="text"
                                :class="{ 'is-invalid' : $v.form.sku.$errors, 'is-valid' : !$v.form.sku.$invalid}"
                                v-model.trim="$v.form.sku.$model"
                                :state="chkState('sku')"
                                aria-describedby="skuFeedback"
                                placeholder="Product ID"
                                autocomplete='new-sku' />
                  <b-form-invalid-feedback id="skuFeedback" >
                    <span v-if="!$v.form.sku.required">- Product ID tidak boleh kosong!</span>
                    <span v-if="!$v.form.sku.isUnique">- Product ID Sudah digunakan!</span>
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group id="firstNameInputGroup1"
                              label="Nama Produk"
                              label-for="firstname">
                  <b-form-input id="name"
                                type="text"
                                v-model.lazy.trim="$v.form.name.$model"
                                :state="chkState('name')"
                                aria-describedby="nameFeedback"
                                placeholder="Nama Produk"
                                autofocus />
                  <b-form-invalid-feedback id="nameFeedback" v-for="(error , index) in errors.form.name" :key="index">
                    - {{ error }} <br>
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group id="typeInputGroup1"
                              label="Tipe Produk"
                              label-for="category">
                  <b-form-select id="category"
                                :plain="true"
                                v-model.lazy.trim="$v.form.category.$model"
                                @change="resetBookingFee"
                                :state="chkState('category')"
                                aria-describedby="categoryFeedback"
                                autofocus >
                    <option value="">Produk</option>
                    <option v-for="(category , key) in categories" :key="key" :value="category.id">- {{ category.name }}</option>
                  </b-form-select>
                  <b-form-invalid-feedback id="categoryFeedback" v-for="(error , index) in errors.form.category" :key="index">
                    - {{ error }} <br>
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group id="source"
                label="Brand"
                label-for="source">
                <b-form-select id="source"
                              :plain="true"
                              v-model.lazy.trim="$v.form.source.$model"
                              aria-describedby="sourceTypeFeedback"
                              autocomplete='given-name'
                              :state="chkState('source')"
                              autofocus
                              :options="[{value: '', text:'Pilih Brand'},
                                                {value: 'PT. Untung Bersama Sejahtera (UBS)', text: 'UBS'},
                                                {value: 'UBS Lifestyle', text: 'UBS Lifestyle'},
                                                {value: 'Tiny Island', text: 'Tiny Island'},
                                                {value: 'Treasury HQ', text: 'TREASURY'},
                                                {value: 'Antam', text: 'ANTAM'}
                                                ]"
                                                >
                </b-form-select>
                <b-form-invalid-feedback id="sourceTypeFeedback">
                  <span v-if="!$v.form.source.required">-Brand tidak boleh kosong!</span>
                </b-form-invalid-feedback>
              </b-form-group>
                <!-- <b-form-group id="lastNameInputGroup2"
                              v-if="this.form.category == 1"
                              label="Description"
                              label-for="lastName">
                  <b-form-textarea id="description"
                                v-model="form.description"
                                aria-describedby="descriptionFeedback"
                                placeholder="Description Product">
                  </b-form-textarea>
                  <b-form-invalid-feedback id="descriptionFeedback" v-for="(error , index) in errors.form.description" :key="index">
                    - {{ error }} <br>
                  </b-form-invalid-feedback>
                </b-form-group> -->
                <!-- <b-form-group id="emailInputGroup4"
                              v-if="this.form.category == 1"
                              label="Units"
                              label-for="email">
                  <b-form-input id="unit"
                                type="number"
                                v-model="form.unit"
                                aria-describedby="unitFeedback"
                                placeholder="0"
                                autocomplete='unit' />
                </b-form-group> -->
              <b-form-group id="userNameInputGroup3"
                            label="Gram Value"
                            label-for="userName">
                <b-form-input id="amount"
                              type="number"
                              v-model.trim="$v.form.amount.$model"
                              :state="chkState('amount')"
                              aria-describedby="gramFeedback"
                              placeholder="Gram Per Unit"
                              autocomplete='amount' />
                <b-form-invalid-feedback id="gramFeedback" v-for="(error , index) in errors.form.amount" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="type"
                label="Minting Type"
                label-for="type">
                <b-form-select id="type"
                              :plain="true"
                              v-model.lazy.trim="$v.form.mintingType.$model"
                              :state="chkState('mintingType')"
                              @change="resetPriceType"
                              aria-describedby="mintingTypeFeedback"
                              autocomplete='given-name'
                              autofocus
                              :options="[{value: '', text:'Pilih Tipe Minting Type'},
                                                {value: 'idr', text: 'IDR'},
                                                {value: 'percent', text: 'Percentage'}]">
                </b-form-select>
                <b-form-invalid-feedback id="mintingTypeFeedback">
                  <span v-if="!$v.form.mintingType.required">- Minting type tidak boleh kosong!</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="type3"
                label="Price Type"
                v-if="this.form.mintingType == 'percent'"
                label-for="pricetype">
                <b-form-select id="pricetype"
                              :plain="true"
                              v-model.lazy.trim="form.priceType"
                              aria-describedby="priiceTypeFeedback"
                              autocomplete='given-name'
                              autofocus
                              :options="[{value: '', text:'Pilih Tipe Price Type'},
                                                {value: 'daily', text: 'Daily Price'},
                                                {value: 'live', text: 'Live Price'}]">
                </b-form-select>
                <b-form-invalid-feedback id="priiceTypeFeedback">
                  <!-- <span v-if="!$v.form.priiceType.required">- Tipe pricing tidak boleh kosong!</span> -->
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="emailInputGroup4"
                            label="Minting Fee"
                            label-for="minting_fee">
                <b-form-input id="minting_fee"
                              type="number"
                              v-model.trim="$v.form.minting_fee.$model"
                              :state="chkState('minting_fee')"
                              aria-describedby="mintingFeeFedback"
                              placeholder="0"
                              autocomplete='minting_fee' />
                <b-form-invalid-feedback id="mintingFeeFedback" v-for="(error , index) in errors.form.minting_fee" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-row>
                <b-col sm="6">
                  <b-form-group id="promotion_type"
                                label="Price Promotion Type (Optional)"
                                label-for="promotion_type">
                    <b-form-select id="promotion_type"
                      v-model="form.promotion_type"
                      :plain="true"
                      :options="[
                        { value: 'no_promotion', text: 'No Promotion' },
                        {value: 'fixed', text: 'Fixed Price'},
                        {value: 'percent', text: 'Discount'}
                      ]">
                    </b-form-select>
                  </b-form-group>
                </b-col>
                 <b-col sm="6">
                  <b-form-group id="promotion_amount"
                                label="Nilai Promosi (Optional)"
                                label-for="promotion_amount">
                    <b-form-input id="promotion_amount"
                                  type="number"
                                  v-model.lazy.trim="$v.form.promotion_amount.$model"
                                  :state="chkState('promotion_amount')"
                                  aria-describedby="promotionAmountFeedback"
                                  placeholder="0"
                                  :disabled="form.promotion_type === 'no_promotion' ? true : false"
                                  autocomplete='given-name'
                                  autofocus />
                    <b-form-invalid-feedback id="promotionAmountFeedback" >
                      <span v-if="!$v.form.promotion_amount.decimal">- Nilai Promosi tidak boleh kurang dari 0!</span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="justify-content-start" v-if="this.form.priceType == 'daily'">
                <b-col md="2" sm="12">
                  <b-form-group id="type3"
                    label="Price Time Minting"
                    label-for="pricetype1">
                  <b-form-select id="pricetype1"
                                :plain="true"
                                v-model="form.hours"
                                aria-describedby="priiceTypeFeedback"
                                autocomplete='given-name'
                                autofocus
                                :options="hours">
                  </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="2" sm="12">
                  <b-form-group id="minutes"
                    label="test"
                    label-for="pricetype2">
                  <b-form-select id="pricetype2"
                                :plain="true"
                                v-model="form.minutes"
                                aria-describedby="priiceTypeFeedback"
                                autocomplete='given-name'
                                autofocus
                                :options="minutes">
                  </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group id="type2"
                v-if="this.form.category == 1"
                label="Booking Type"
                label-for="type">
                <b-form-select id="type2"
                              :plain="true"
                              v-model="form.bookingType"
                              @change="resetPriceTypeBooking"
                              aria-describedby="bookingTypeFeedback"
                              autocomplete='given-name'
                              autofocus
                              :options="[{value: '', text:'Pilih Tipe Booking Type'},
                                                {value: 'idr', text: 'IDR'},
                                                {value: 'percent', text: 'Percentage'}]">
                </b-form-select>
                <b-form-invalid-feedback id="bookingTypeFeedback">
                  <!-- <span v-if="!$v.form.bookingType.required">- Booking type tidak boleh kosong!</span> -->
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="type4"
                label="Price Type Booking"
                v-if="this.form.bookingType == 'percent'"
                label-for="pricetype2">
                <b-form-select id="pricetype2"
                              :plain="true"
                              v-model="form.priceTypeBooking"
                              aria-describedby="priiceTypeFeedback"
                              autocomplete='given-name'
                              autofocus
                              :options="[{value: '', text:'Pilih Tipe Price Type'},
                                                {value: 'daily', text: 'Daily Price'},
                                                {value: 'live', text: 'Live Price'}]">
                </b-form-select>
                <b-form-invalid-feedback id="priiceTypeBookingFeedback">
                  <!-- <span v-if="!$v.form.priiceType.required">- Tipe pricing tidak boleh kosong!</span> -->
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="emailInputGroup4"
                            label="Booking Fee"
                            v-if="this.form.category == 1"
                            label-for="email">
                <b-form-input id="booking_fee"
                              type="number"
                              v-model="form.booking_fee"
                              aria-describedby="bookingFeeFedback"
                              placeholder="0"
                              autocomplete='booking_fee' />
                <b-form-invalid-feedback id="bookingFeeFedback" v-for="(error , index) in errors.form.booking_fee" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-row class="justify-content-start" v-if="this.form.priceTypeBooking == 'daily'">
                <b-col md="2" sm="12">
                  <b-form-group id="type3"
                    label="Price Time Booking"
                    label-for="pricetype1">
                  <b-form-select id="pricetype1"
                                :plain="true"
                                v-model="form.hoursBooking"
                                aria-describedby="priiceTypeFeedback"
                                autocomplete='given-name'
                                autofocus
                                :options="hoursBooking">
                  </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="2" sm="12">
                  <b-form-group id="minutes"
                    label="test"
                    label-for="pricetype2">
                  <b-form-select id="pricetype2"
                                :plain="true"
                                v-model="form.minutesBooking"
                                aria-describedby="priiceTypeFeedback"
                                autocomplete='given-name'
                                autofocus
                                :options="minutesBooking">
                  </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group
               id="originInputGroup2"
               label="Product Original Delivery"
               label-for="origin"
              >
                <b-form-select
                 id="origin"
                 :plain="true"
                 v-model.lazy.trim="$v.form.origin.$model"
                 aria-describedby="originFeedback"
                 :state="chkState('origin')"
                 autocomplete='given-name'
                 autofocus
                >
                <option v-for="(item, key) in originOption" :key="key" :value="item.value">{{ item.text }}</option>
                </b-form-select>
                <b-form-invalid-feedback id="originFeedback">
                  <span v-if="!$v.form.origin.required">- Deleviry origin tidak boleh kosong!</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
               id="stockInputGroup2"
               label="Jumlah Stok"
               label-for="stock"
              >
                <b-form-input
                id="stock"
                type="number"
                v-model.trim="$v.form.stock.$model"
                :state="chkState('stock')"
                aria-describedby="stockFeedback"
                placeholder="Jumlah Stok"
                autocomplete='stock' />
                <b-form-invalid-feedback id="stockFeedback">
                  <span v-if="!$v.form.stock.required">Jumlah stok tidak boleh kosong!</span>
                  <span v-if="!$v.form.stock.decimal">Jumalh stock tidak boleh kurang dari 0!</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="userNameInputGroup3"
                            label="Image"
                            label-for="userName">
                  <b-form-file
                                :plain="true"
                                accept="image/*"
                                multiple
                                aria-describedby="imageFeedback"
                                v-model.lazy.trim="$v.form.selectedImage.$model"
                                :state="chkState('selectedImage')"
                                @change="onFileSelected">
                  </b-form-file>
                <b-form-invalid-feedback id="imageFeedback" v-for="(error , index) in errors.form.image" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
                <span id="imageFeedback" v-if="!$v.form.selectedImage.maxArray">- Image tidak boleh lebih dari 5 gambar</span>
                <span id="imageFeedback" v-if="this.imageSizeError === true">- Max size per file: 512kb</span>
              </b-form-group>

              <b-row v-if="selectedImage.length > 0">
                <b-col cols="12" style="display: flex; gap: 6px; flex-wrap: wrap;">
                  <div v-for="(item, index) in selectedImage" :key="index">
                    <div class="image-preview">
                      <ul class="image-preview-list">
                        <li class="image-preview-list-item">
                          <img :src="item" alt="">
                        </li>
                        <li>
                          <b-button variant="danger" class="button-delete" @click="deletePreviewImage(index)"><i class="fa fa-trash"></i></b-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </b-col>
              </b-row>

              <b-form-group
               id="mainImageInputGroup3"
               label="Main Image (Thumbnail)"
               label-for="mainImage"
              >
                <b-form-select
                 id="mainImage"
                 :plain="true"
                 v-model.trim.lazy="$v.form.mainImage.$model"
                 aria-describedby="mainImageFeedback"
                 :state="chkState('mainImage')"
                >
                  <option value="">Pilih Main Image</option>
                  <option v-for="(item, index) in listMainImage" :key="index" :value="item.path">{{ item.name }}</option>
                </b-form-select>
                <b-form-invalid-feedback id="mainImageFeedback">
                  <span v-if="!$v.form.mainImage.required">Main image tidak boleh kosong!</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
               id="contentInputGroup2"
               label="Deskripsi Produk"
               label-for="content"
              >
                <quill-editor
                 style="margin-bottom:5px"
                 v-model.lazy.trim="$v.form.content.$model"
                 :state="chkState('content')"
                 autocomplete='family-name'
                ></quill-editor>
                <div v-if="this.form.content === ''">
                  <span id="contentFeedback">Deskripsi tidak boleh kosong!</span>
                </div>
              </b-form-group>
              <b-button type="submit" variant="primary">
                Submit
              </b-button>
            </b-form>
            <br/>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, helpers  } from "vuelidate/lib/validators"
import moment from 'moment'

let image_validate = []
const maxArray = (value) => !helpers.req(value) || image_validate.length <=5

const decimal = helpers.regex(
  "decimal",
  /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)(?:[eE][+-]?[0-9]+)?$/
);

export default {
  name: "ValidationForms",
  data() {
    return {
      form: {
        name: '',
        //description: '',
        amount: '',
        //unit: '',
        sku: '',
        minting_fee: '',
        booking_fee: '',
        selectedImage: [],
        mintingType: '',
        bookingType: '',
        priceType: '',
        priceTypeBooking: '',
        category: '',
        source: '',
        hours: '',
        minutes: '',
        hoursBooking: '',
        minutesBooking: '',
        promotion_type: '',
        promotion_amount: '',
        origin: '',
        stock: '',
        mainImage: '',
        content: ''
      },
      hours: '',
      minutes: '',
      hoursBooking: '',
      minutesBooking: '',
      date: moment().format('YYYY-MM-DD HH:mm'),
      dateBooking: moment().format('YYYY-MM-DD HH:mm'),
      max: moment().format('YYYY-MM-DD') ,
      categories: [],
      listMainImage: [],
      imageSizeError: false,
      selectedImage: [],
      errors: {
        message: [],
        status: [],
        code: '',
        headers: [],
        form: {
          name: [],
          //description: [],
          amount: [],
          category:[],
          //unit: [],
          sku: [],
          selectedImage: [],
          minting_fee: [],
          booking_fee: [],
          image: [],
          origin: [],
          stock: [],
          mainImage: '',
          content: []
        },
      },
      isLoading: false,
      originOption: [
        {value: '', text: 'Pilih Lokasi'},
        { value: 'jakarta', text: 'Jakarta' },
        { value: 'surabaya', text: 'Surabaya' }
      ]
    }
  },
  computed: {
    formStr() { return JSON.stringify(this.form, null, 4) },
    isValid() { return !this.$v.form.$anyError },
    isDirty() { return this.$v.form.$anyDirty },
    invCheck() { return 'You must accept before submitting' },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
      },
      source: {
        required
      },
      amount: {
        required
      },
      category: {
        required
      },
      mintingType: {
        required
      },
      sku: {
        required,
        isUnique (value) {
          if (value === '') return true

          return new Promise ((resolve, reject) => {
            setTimeout(() => {
              this.$http.get(`product-check-sku/${value}`)
              .then((success) => {
                if (success.data == false) {
                  resolve(true)
                  return true
                }else{
                  reject(false)
                  return false
                }
              })
              .catch((error) => {
                if (error.response) {
                  this.errors.code = error.response.status;
                  this.errors.status = error.response.data.meta.code;
                  this.errors.headers = error.response.headers;
                }
              })
            }, 350 + Math.random() * 300)
          })
        }
      },
      minting_fee: {
        required
      },
      selectedImage: {
        required,
        maxArray
      },
      promotion_amount: {
        decimal,
      },
      origin: {
        required
      },
      stock: {
        required,
        decimal
      },
      mainImage: {
        required
      },
      content: {
        required
      }
    }
  },
  created () {
    this.$http.get('tag-product').then((result) =>{
      this.categories = result.data.data
    });

    this.$http.get('minting/main-image').then((result) => {
      this.listMainImage = result.data.data
    })

    let optionsHours = [
      { value: '', text: '=== Pilih Jam ==='},
      { value: '00' , text: '00'},{ value: '01' , text: '01'},{ value: '02' , text: '02'},
      { value: '03' , text: '03'},{ value: '04' , text: '04'},{ value: '05' , text: '05'},
      { value: '06' , text: '06'},{ value: '07' , text: '07'},{ value: '08' , text: '08'},
      { value: '09' , text: '09'},{ value: '10' , text: '10'},{ value: '11' , text: '11'},
      { value: '12' , text: '12'},{ value: '13' , text: '13'},{ value: '14' , text: '14'},
      { value: '15' , text: '15'},{ value: '16' , text: '16'},{ value: '17' , text: '17'},
      { value: '18' , text: '18'},{ value: '19' , text: '19'},{ value: '20' , text: '20'},
      { value: '21' , text: '21'},{ value: '22' , text: '22'},{ value: '23' , text: '23'},
    ]
    let optionsMinutes = [
      { value: '', text: '=== Pilih Menit ==='},
      { value: '00' , text: '00'},{ value: '01' , text: '01'},{ value: '02' , text: '02'},
      { value: '03' , text: '03'},{ value: '04' , text: '04'},{ value: '05' , text: '05'},
      { value: '06' , text: '06'},{ value: '07' , text: '07'},{ value: '08' , text: '08'},
      { value: '09' , text: '09'},{ value: '10' , text: '10'},{ value: '11' , text: '11'},
      { value: '12' , text: '12'},{ value: '13' , text: '13'},{ value: '14' , text: '14'},
      { value: '15' , text: '15'},{ value: '16' , text: '16'},{ value: '17' , text: '17'},
      { value: '18' , text: '18'},{ value: '19' , text: '19'},{ value: '20' , text: '20'},
      { value: '21' , text: '21'},{ value: '22' , text: '22'},{ value: '23' , text: '23'},
      { value: '24' , text: '24'},{ value: '25' , text: '25'},{ value: '26' , text: '26'},
      { value: '27' , text: '27'},{ value: '28' , text: '28'},{ value: '29' , text: '29'},
      { value: '30' , text: '30'},{ value: '31' , text: '31'},{ value: '32' , text: '32'},
      { value: '33' , text: '33'},{ value: '34' , text: '34'},{ value: '35' , text: '35'},
      { value: '36' , text: '36'},{ value: '37' , text: '37'},{ value: '38' , text: '38'},
      { value: '39' , text: '39'},{ value: '40' , text: '40'},{ value: '41' , text: '41'},
      { value: '42' , text: '42'},{ value: '43' , text: '43'},{ value: '44' , text: '44'},
      { value: '45' , text: '45'},{ value: '46' , text: '46'},{ value: '47' , text: '47'},
      { value: '48' , text: '48'},{ value: '49' , text: '49'},{ value: '50' , text: '50'},
      { value: '51' , text: '51'},{ value: '52' , text: '52'},{ value: '53' , text: '53'},
      { value: '54' , text: '54'},{ value: '55' , text: '55'},{ value: '56' , text: '56'},
      { value: '57' , text: '57'},{ value: '58' , text: '58'},{ value: '59' , text: '59'},
    ]


    this.hours = optionsHours
    this.minutes = optionsMinutes
    this.hoursBooking = optionsHours
    this.minutesBooking = optionsMinutes
  },
  methods: {
    onFileSelected(e) {
      const files = e.target.files
      for (let index = 0; index < files.length; index++) {
        image_validate = [...image_validate, files[index]]
        this.form.selectedImage = [...this.form.selectedImage, files[index]]
        const reader = new FileReader()
        reader.onload = (e) => this.selectedImage.push(e.target.result)
        reader.readAsDataURL(files[index])
        if(Math.ceil(files[index].size) > 512000) {
          this.imageSizeError = true
        }
      }
    },
    deletePreviewImage(index) {
      this.form.selectedImage = image_validate
      for (let i = 0; i < this.form.selectedImage.length; i++) {
        if (i === index) {
          if (image_validate[index].size > 512000) {
            this.imageSizeError = false
          }
          image_validate.splice(i, 1)
          this.form.selectedImage = image_validate
          this.selectedImage.splice(i, 1)
        }
      }
    },
    // checkSize(value) {
    //   if (value.size)
    // },
    resetPriceType(val) {
      if (val == 'idr') {
        this.form.priceType = ''
        this.form.minting_fee = ''
      }
    },
    resetPriceTypeBooking(val) {
      if (val == 'idr') {
        this.form.priceTypeBooking = ''
        this.form.booking_fee = ''
      }
    },
    resetBookingFee(val) {
      if (val == 2) {
        this.form.booking_fee = ''
      }
    },
    dinarPost() {
      this.validate()
      this.isLoading = true
      const formData =  new FormData();
        formData.append('name', this.form.name)
        formData.append('minting_type', this.form.mintingType)
        formData.append('amount', this.form.amount)
        //formData.append('unit', this.form.unit)
        formData.append('sku', this.form.sku)
        formData.append('minting_fee', this.form.minting_fee)
        formData.append('type', this.form.category)
        formData.append('source', this.form.source)
        formData.append('sub_minting_type', this.form.priceType)
        formData.append('booking_fee', this.form.booking_fee)
        formData.append('price_hours', this.form.hours)
        formData.append('price_minutes', this.form.minutes)
        formData.append('description', this.form.content)
        formData.append('booking_type', this.form.bookingType)
        formData.append('sub_booking_type', this.form.priceTypeBooking)
        formData.append('price_hours_booking', this.form.hours)
        formData.append('price_minutes_booking', this.form.minutesBooking)
        formData.append("promotion_type", this.form.promotion_type === 'no_promotion' ? '' : this.form.promotion_type);
        formData.append("promotion_amount", this.form.promotion_type === 'no_promotion' ? '' : this.form.promotion_amount);
        this.form.selectedImage.map((item, index) => {
          formData.append('image['+ index +']', item)
        })
        formData.append("main_image", this.form.mainImage)
        formData.append("origin", this.form.origin)
        formData.append("stock", this.form.stock)



      this.$http.post(`add-product-sku`, formData)
      .then((res) => {
        console.log(res)
        this.isLoading = false
        this.$toasted.success('Product successfully created!')
        this.$router.push({name: 'List Koin Nusantara Product'});
      }).catch((error) => {
        console.log(error)
        if (error.response) {
          console.log('here')
          console.log(this.errors.status)
          this.isLoading = false
          this.errors.status = error.response.data.meta.code;
          this.errors.code = error.response.status;
          this.errors.headers = error.response.headers;
          if(this.errors.status == 422) {
            this.$toasted.error('Data tidak valid. Mohon periksa kembali isian form yang tersedia.')
            this.errors.message = error.response.data.data;
            this.errors.form.name = this.errors.message.name;
            this.errors.form.description = this.errors.message.description;
            this.errors.form.amount = this.errors.message.amount;
            //this.errors.form.unit = this.errors.message.unit;
            this.errors.form.sku = this.errors.message.sku;
            this.errors.form.minting_fee = this.errors.message.minting_fee;
            this.errors.form.booking_fee = this.errors.message.booking_fee;
            this.errors.form.image = this.errors.message.image;
            this.errors.form.category = this.errors.message.minting_type;
          }else if(this.errors.status == 400) {
            this.$swal.fire(
              'Failed!',
              error.response.data.data || error.response.data.meta.message,
              'error'
            )
          }else if(this.errors.status === 500) {
            this.$swal.fire(
              'Failed!',
              error.response.data.data || error.response.data.meta.message,
              'error'
            )
          }
        }
      })
    },
    chkState(val) {
      const field = this.$v.form[val]
      return !field.$dirty || !field.$invalid
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus()
          return true
        }
        if (component.$refs.check) {
          component.$refs.check.focus()
          return true
        }
      }
      let focused = false
      component.$children.some((child) => {
        focused = this.findFirstError(child)
        return focused
      })

      return focused
    },
    validate() {
      this.$v.$touch()
      this.$nextTick(() => this.findFirstError())
      return this.isValid
    }
  }
}
</script>

<style scoped>
.btn.disabled {
  cursor: auto;
}

.invalid-feedback {
    font-size: 100% !important;
}
</style>
<style lang="scss" scoped>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

.image-preview {
  width: 100%;
  height: auto;

  &-list {
    display: flex;
    list-style: none;
    padding: 0;
  }

  & > ul > li> img {
    width: 100%;
  max-width: 100px;
  height: 100px;
  }
}

.button-delete {
  font-size: 10px;
}

.skus {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

#minutes__BV_label_ {
  visibility: hidden;
}

#contentFeedback {
  color: #f86c6b !important;
  font-size: 100%;
}

#imageFeedback {
  color: #f86c6b !important;
  font-size: 100%;
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
